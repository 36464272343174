import React, { useState, useEffect } from 'react';
import { isEnterKey } from '../utils/keyEvents';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Chip,
  withStyles,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const UserDataDialog = ({
  classes,
  open,
  error,
  isSubmitting,
  onClose,
  onSubmit,
  countryList,
  currencyList,
  userProfileData,
}) => {
  const { t } = useTranslation();
  const [formError, setFormError] = useState('');
  const [userData, setUserData] = useState(userProfileData);

  function handleEnterPress(event) {
    if (isEnterKey(event)) {
      event.stopPropagation();
      handleSubmit();
    }
  }

  function handleClose() {
    onClose();
  }

  function handleSubmit() {
    // Check if all fields has value
    //if (Object.keys(userData).some((key) => !userData[key])) return;

    const user = { ...userData };
    onSubmit(user);
  }

  function handleFieldChange(event) {
    const { target } = event;
    setUserData({ ...userData, [target.name]: target.value });
  }

  const submitError = error || formError;
  const disabled = isSubmitting; // || Object.keys(userData).some((key) => !userData[key]);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      onKeyPress={handleEnterPress}
    >
      <DialogContent className={classes.dialog}>
        {!!submitError && (
          <Chip
            className={classes.errorChip}
            color="secondary"
            icon={<ErrorIcon />}
            label={submitError}
          />
        )}
        <form>
          <TextField
            name="username"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_name')}
            fullWidth
            value={userData.username}
            onChange={handleFieldChange}
          />
          <TextField
            name="countryCode"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_country')}
            fullWidth
            select
            value={userData.countryCode}
            onChange={handleFieldChange}
          >
            {countryList.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="zipCode"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_zip')}
            fullWidth
            value={userData.zipCode}
            onChange={handleFieldChange}
          />
          <TextField
            name="state"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_state')}
            fullWidth
            value={userData.state}
            onChange={handleFieldChange}
          />
          <TextField
            name="city"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_city')}
            fullWidth
            value={userData.city}
            onChange={handleFieldChange}
          />
          <TextField
            name="street"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_street')}
            fullWidth
            value={userData.street}
            onChange={handleFieldChange}
          />
          <TextField
            name="building"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_building')}
            fullWidth
            value={userData.building}
            onChange={handleFieldChange}
          />
          <TextField
            name="flat"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_flat')}
            fullWidth
            value={userData.flat}
            onChange={handleFieldChange}
          />
          <TextField
            name="phone"
            type="tel"
            autoComplete="tel"
            className={classes.textField}
            variant="outlined"
            label={t('user_phone')}
            fullWidth
            value={userData.phone}
            onChange={handleFieldChange}
          />
          <TextField
            name="email"
            type="email"
            autoComplete="current-email"
            className={classes.textField}
            variant="outlined"
            label={t('user_email')}
            fullWidth
            value={userData.email}
            onChange={handleFieldChange}
          />
          <TextField
            name="contactPerson"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_contact_person')}
            fullWidth
            value={userData.contactPerson}
            onChange={handleFieldChange}
          />
          <TextField
            name="trn"
            autoComplete=""
            className={classes.textField}
            variant="outlined"
            label={t('user_tax')}
            fullWidth
            value={userData.trn}
            onChange={handleFieldChange}
          />
          <TextField
            name="currencyCode"
            className={classes.textField}
            variant="outlined"
            label={t('user_currency')}
            fullWidth
            select
            value={userData.currencyCode}
            onChange={handleFieldChange}
          >
            {currencyList.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </form>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={handleSubmit}
        >
          SAVE
        </Button>
      </DialogContent>
    </Dialog>
  );
};

UserDataDialog.defaultProps = {
  open: true,
};

const styles = (theme) => {
  const colors = theme.palette.custom.userDataDialog;

  return {
    dialog: {
      backgroundColor: colors.dialog,
    },
    button: {
      marginTop: theme.spacing(1) * 2,
      width: '100%',
    },
    nameContainer: {
      display: 'flex',
    },
    nameTextField: {
      marginTop: 0,
      backgroundColor: colors.textField.background,
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },
    textField: {
      backgroundColor: colors.textField.background,
      margin: theme.spacing(1),
    },
    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
  };
};

export default withStyles(styles)(UserDataDialog);
