import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as orderApi from '../api/order-api';
import OrdersDetailedTable from '../components/OrdersDetailedTable';
import {
  Page,
  OrdersTable,
  ActionsGroup,
  SearchInputWithSelect,
  Spinner,
} from '../components';
import { Redirect } from 'react-router-dom';
import { saveExcel } from '../utils/excel';
import { Switch, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const OrdersPage = ({ classes }) => {
  const { t } = useTranslation();

  const filterTypes = [
    { title: t('part_number'), value: 1 },
    { title: t('order_number'), value: 2 },
    { title: t('reference'), value: 3 },
  ];

  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [data, setData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [filterType, setFilterType] = useState(filterTypes[0].value);
  const [filter, setFilter] = useState('');
  const [displayDetailed, setDisplayDetailed] = useState(true);
  const [progress, setProgress] = useState(false);

  async function fetchData(query) {
    setProgress(true);
    let res;
    try {
      if (displayDetailed) {
        res = await orderApi.portions(query);
        setDetailedData(res);
      } else {
        res = await orderApi.states(query);
        setData(res);
      }
    } finally {
      setProgress(false);
    }
  }

  useEffect(() => {
    if (isLoggedIn && (!data.length || !detailedData.length)) fetchData();
  }, [isLoggedIn, displayDetailed]);

  function handleSearchTypeChange(value) {
    setFilterType(value);
  }

  function handleFilterChange(value) {
    setFilter(value.trim().toLowerCase());
  }

  function handleSearch() {
    setData([]);
    const query = {};
    if (filter)
      switch (filterType) {
        case 1:
          query.partNumber = filter;
          break;
        case 2:
          query.id = filter;
          break;
        case 3:
          query.yourReference = filter;
          break;
        case 4:
          query.yourOrderId = filter;
          break;
      }
    fetchData(query);
  }

  function handleAllClick() {
    setFilter('');
    setData([]);
    fetchData();
  }

  const columns = useMemo(() => [
    { name: 'Order', width: 10 },
    { name: 'Date', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Brand', width: 15 },
    { name: 'Order Price', width: 10 },
    { name: 'Sale Price', width: 10 },
    { name: 'Currency', width: 10 },
    { name: 'InWork', width: 10 },
    { name: 'Ordered', width: 10 },
    { name: 'Confirmed', width: 10 },
    { name: 'To Dispatch', width: 10 },
    { name: 'Dispatched', width: 10 },
    { name: 'Refused', width: 10 },
    { name: 'Reference', width: 20 },
    { name: 'Description', width: 50 },
    { name: 'State Date', width: 15 },
  ]);

  const detailedColumns = useMemo(
    () => [
      { name: 'Order', width: 10 },
      { name: 'Part Number', width: 15 },
      { name: 'Brand', width: 15 },
      { name: 'Quantity', width: 10 },
      { name: 'Order Price', width: 10 },
      { name: 'Currency', width: 10 },
      { name: 'Destination', width: 15 },
      { name: 'Reference', width: 20 },
      { name: 'Description', width: 50 },
      { name: 'State', width: 10 },
      { name: 'State Date', width: 15 },
    ],
    []
  );

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.orderId,
        value.orderDate?.slice(0, 10),
        value.partNumber,
        value.brand,
        value.priceOrder,
        value.priceSale,
        value.currency,
        value.inwork,
        value.ordered,
        value.purchased,
        value.accepted,
        value.shipped,
        value.refused,
        value.yourReference,
        value.description,
        value.stateDate.slice(0, 16).replace('T', ' '),
      ]),
    [data]
  );

  const detailedOutputData = useMemo(
    () =>
      detailedData.map((value) => [
        value.orderId, // Order
        value.partNumber, // Part Number
        value.brand, // Brand
        value.quantity, // Quantity
        value.price, // Order Price
        value.currency, // Currency
        value.destination, // Destination
        value.yourReference, // Reference
        value.description, // Description
        value.state, // State (translated)
        value.stateDate.slice(0, 16).replace('T', ' '), // State Date
      ]),
    [detailedData]
  );

  async function handleToExcel() {
    if (displayDetailed) {
      await saveExcel('OrdersStates', detailedColumns, detailedOutputData);
    } else {
      await saveExcel('OrdersStates', columns, outputData);
    }
  }

  return (
    <Page>
      {!isLoggedIn && <Redirect to="/search" />}
      <ActionsGroup className={classes.actionsGroup}>
        <div className={classes.actionsLeft}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={handleAllClick}
          >
            {t('all')}
          </Button>
          <SearchInputWithSelect
            className={classes.searchInput}
            selectItems={filterTypes}
            selectValue={filterType}
            searchValue={filter}
            onSelectChange={handleSearchTypeChange}
            onSearchClick={handleSearch}
            onSearchChange={handleFilterChange}
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={displayDetailed}
                onChange={(e) => {
                  setDisplayDetailed(e.target.checked);
                }}
              />
            }
            label={
              displayDetailed
                ? t('order_table_details')
                : t('order_table_summary')
            }
            style={{ marginLeft: 1 }}
          />
        </div>
        <Button
          disabled={!data.length}
          variant="contained"
          // color="primary"
          className={classes.excelButton}
          onClick={handleToExcel}
        >
          {t('export')}
        </Button>
      </ActionsGroup>
      {progress ? (
        <div className={classes.spinnerContainer}>
          {progress ? <Spinner /> : null}
        </div>
      ) : (
        <div className={classes.tableContainer}>
          {displayDetailed ? (
            <OrdersDetailedTable data={detailedData} />
          ) : (
            <OrdersTable data={data} />
          )}
        </div>
      )}
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.ordersPage;
  const mainContainer = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.tabSelectedBackground,
    height: '72px',
    minHeight: '72px',
    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  };
  return {
    tableContainer: theme.tableContainer,
    actionsGroup: {
      justifyContent: 'flex-end',
    },
    actionsLeft: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'center',
    },
    button: {},
    excelButton: {
      backgroundColor: colors.excelButton.default,
      color: colors.excelButton.fontColor,
      '&:hover': {
        backgroundColor: colors.excelButton.hovered,
      },
    },
    searchInput: {
      marginLeft: theme.spacing(1),
    },
    spinnerContainer: {
      ...mainContainer,
      marginTop: theme.spacing(1) * 6,
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
  };
};

export default withStyles(styles)(OrdersPage);
