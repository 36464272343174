import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

const styles = (theme) => ({
  table: {
    width: '100%',
  },
});

const stateColors = {
  ordered: '#FFBF00', // Yellow: Indicates the order has been placed, but no further action yet
  inwork: '#1E90FF', // Light Blue: Represents work in progress or processing
  purchased: '#7B68EE', // Purple: Indicates the order has been confirmed or purchased
  accepted: '#32CD32', // Green: Positive state, shows that the order is accepted
  shipped: '#00CED1', // Cyan: Represents the shipment, usually in transit
  refused: '#FF6347', // Red: Indicates the order has been refused or canceled
  returned: '#FF4500', // Dark Orange: Represents a returned order
};

const OrdersDetailedTable = ({ classes, data }) => {
  const { t } = useTranslation();
  const currency = data.length ? data[0].currency : '';
  //console.log(data);
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>{t('order')}</TableCell>
          <TableCell>{t('part_number')}</TableCell>
          <TableCell>{t('brand')}</TableCell>
          <TableCell>{t('quantity')}</TableCell>
          <TableCell>
            <TableCell>{t('price_with_currency', { currency })}</TableCell>
          </TableCell>

          <TableCell>{t('destination')}</TableCell>
          <TableCell>{t('reference')}</TableCell>
          <TableCell>{t('description')}</TableCell>
          <TableCell>{t('state')}</TableCell>
          <TableCell>{t('state_date')}</TableCell>
          <TableCell>{t('document')}</TableCell>
          <TableCell>{t('comment')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((value) => (
          <TableRow key={value.id}>
            <TableCell>{value.orderId}</TableCell>
            <TableCell>{value.partNumber}</TableCell>
            <TableCell>{value.brand}</TableCell>
            <TableCell>{value.quantity}</TableCell>
            <TableCell>{value.price}</TableCell>
            <TableCell>{value.destination}</TableCell>
            <TableCell>{value.yourReference}</TableCell>
            <TableCell>{value.description}</TableCell>
            <TableCell>
              <Tooltip title={t(`tooltips.${value.state}`)}>
                <Chip
                  size="small"
                  label={t(`states.${value.state}`)}
                  variant="outlined"
                  style={{
                    borderColor: stateColors[value.state],
                    color: stateColors[value.state],
                  }}
                />
              </Tooltip>
            </TableCell>
            <TableCell>
              {value.stateDate.slice(0, 16).replace('T', ' ')}
            </TableCell>
            <TableCell>{value.document}</TableCell>
            <TableCell>{value.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

OrdersDetailedTable.defaultProps = {
  data: [],
};

export default withStyles(styles)(OrdersDetailedTable);
